import './App.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

//import Main from './Main';
import 'moment/locale/es';

//theme
import 'primereact/resources/themes/mira/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'react-toastify/dist/ReactToastify.css';

// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import { PrimeReactProvider, addLocale } from 'primereact/api';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AppMain from './AppMain';

function App() {
	const queryClient = new QueryClient({
		refetchOnWindowFocus: false,
		refetchOnmount: false,
		refetchOnReconnect: false,
		retry: false,
		defaultOptions: {
			queries: {
				staleTime: Infinity,
				cacheTime: 10 * 60 * 1000,
			},
		},
	});

	addLocale('es', {
		firstDayOfWeek: 1,
		dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
		dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
		dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
		monthNames: [
			'enero',
			'febrero',
			'marzo',
			'abril',
			'mayo',
			'junio',
			'julio',
			'agosto',
			'septiembre',
			'octubre',
			'noviembre',
			'diciembre',
		],
		monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
		today: 'Hoy',
		clear: 'Limpiar',
		dateFormat: 'dd/mm/yy',
	});

	return (
		<PrimeReactProvider>
			<QueryClientProvider client={queryClient}>
				<div className='App'>
					<AppMain />
					<ToastContainer autoClose={2000} />
				</div>
				<ReactQueryDevtools initialIsOpen />
			</QueryClientProvider>
		</PrimeReactProvider>
	);
}

export default App;
