import NavItem from '../components/nav/NavItem';
import AppLayout from '../components/layout/AppLayout';

const InicioPage = ()=>{

    return (
			<AppLayout>
				<div className='w-full p-2' style={{ maxHeight: '100vh' }}>
					<div
						className='flex flex-column lg:flex-row gap-3 justify-content-between lg:align-items-center border-primary py-2'
						style={{ borderBottom: '7px solid' }}
					>
						<span className='text-900 font-medium text-2xl'> Seleccione aplicación </span>
					</div>
					<ul className='list-none p-1'>
						<li className='border-bottom-1 surface-border py-3md:align-items-center md:justify-content-between'>
							<NavItem title='Obras' icon='pi pi-building' url='/obras'/>
						</li>
						<li className='border-bottom-1 surface-border py-3 md:align-items-center md:justify-content-between'>
							<NavItem title='Desplaza' icon='pi pi-car' url='/desplaza' />
						</li>
					</ul>
				</div>
			</AppLayout>
		);
};



export default InicioPage;