import { useQuery } from '@tanstack/react-query';
import {  getPersonalProveedor } from '../services/reactivaService';
import { useMemo } from 'react';

const usePersonalProveedorFetch = ({id}={}) => {
	const { data: allPersonalProveedor, isLoading: isLoadingPersonal } = useQuery({
		queryKey: ['personalProveedor'],
		queryFn: async () => await getPersonalProveedor(),
	});

	const personalProveedor = useMemo(()=>(
		allPersonalProveedor?.filter(p=>p.proveedor===id)
	),[allPersonalProveedor, id]);

	return { allPersonalProveedor,personalProveedor, isLoadingPersonal };
};

export default usePersonalProveedorFetch;
