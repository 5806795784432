import { BrowserRouter } from 'react-router-dom';
import Main from './Main';
import { useEffect, useState } from 'react';
import { useLocalStorage } from './hooks/useLocalStorage';
import AppContext from './contexts/AppContext';

const AppMain = () => {
	const [user, setUser] = useState(null);
	const { getItem } = useLocalStorage();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const savedU = getItem('user');
		if (savedU && JSON.parse(savedU) !== user) {
			setUser(JSON.parse(savedU));
		}
		setLoading(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AppContext.Provider value={{ user, setUser }}>
			<BrowserRouter>{!loading && <Main />}</BrowserRouter>
		</AppContext.Provider>
	);
};

export default AppMain;
