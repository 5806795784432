import { memo, useMemo } from "react";
import useObrasFetch from "../../hooks/useObrasFetch";
import { useParams } from "react-router";
import usePartesTrabajoFetch from "../../hooks/usePartesTrabajoFetch";
import AppLayout from "../../components/layout/AppLayout";
import AppBreadcrumb from "../../components/layout/AppBreadcrumb";
import ListadoElement from "../../components/components/ListadoElement";
import usePersonalProveedorFetch from "../../hooks/usePersonalProveedorFetch";
import useProveedoresFetch from "../../hooks/useProveedoresFetch";

const PersonalObraHoy = ()=>{
	const { id } = useParams();
	const { obras } = useObrasFetch();

	const obra = useMemo(() => obras?.find((o) => o.id === parseInt(id, 10)), [obras, id]);
	const { allPersonalProveedor } = usePersonalProveedorFetch();
	const { proveedores } = useProveedoresFetch();

    const { partesTrabajoAbiertos } = usePartesTrabajoFetch({ obra: id });

	const breadcrumb = useMemo(
		() => [
			{
				label: 'Inicio',
				icon: 'pi pi-home',
				url: '/',
			},
			{
				label: 'Obras',
				url: '/obras',
			},
			{
				label: obra?.nombre,
				url: `/obra/${id}`,
			},
			{
				label: 'Personal en obra hoy',
			},
		],
		[obra?.nombre, id]
	);

    console.log(partesTrabajoAbiertos);
	const datos = useMemo(
		() =>
			partesTrabajoAbiertos?.map((p) => ({
				...p,
				personaNombre: allPersonalProveedor?.find((pp) => pp.id === p.persona)?.nombre,
				proveedorNombre: proveedores?.find((pp) => pp.id === p.proveedor)?.nombre,
				horaEntrada: new Date(p.horaEntrada),
				conTarjeta: !!p.tarjeta,
			})),
		[partesTrabajoAbiertos, allPersonalProveedor, proveedores]
	);

    return (
			<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
				<div className='w-full p-2' style={{ maxHeight: '100vh' }}>
					<div className='surface-card shadow-2 border-round p-4'>
						<div className='flex justify-content-between align-items-center mb-5'>
							<span class='text-xl text-900 font-medium'>Personal en obra</span>
							<div></div>
						</div>
						<ul className='list-none p-0 m-0'>
							{datos?.map((d) => (
								<ListadoElement
									titulo={d?.personaNombre}
									subtitulo1={d?.proveedorNombre}
									//badge='PENDING'
									bgcolor='bg-yellow-50'
									// badgeColor='bg-green-100'
									right={
										<>
											<span class='text-yellow-700 font-bold'>{d?.horaEntrada?.toLocaleDateString()}</span>
											<p className='mt-1 mb-0 text-yellow-700'>{d?.horaEntrada?.toLocaleTimeString()}</p>
										</>
									}
								/>
							))}
						</ul>
					</div>
				</div>
			</AppLayout>
		);
}

export default memo(PersonalObraHoy);
