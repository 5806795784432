import { useContext } from "react";
import { getObrasByUsuario, getObrasService } from "../services/reactivaService";
import { useQuery } from '@tanstack/react-query';
import AppContext from '../contexts/AppContext';

const useObrasFetch = () => {
    const {user} = useContext(AppContext);

	const { data: obras, isLoading: isLoadingObras } = useQuery({
		queryKey: ['obras'],
		queryFn: async () => await getObrasService(),
	});

    const { data: obrasUsuario } = useQuery({
			queryKey: ['obrasUsuario', user?.id],
			queryFn: async () => await getObrasByUsuario(user?.id),
			enabled: !!user?.id,
		});

    return { obrasUsuario, obras, isLoadingObras };

};

export default useObrasFetch;