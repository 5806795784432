import AppLayout from '../../components/layout/AppLayout';
import AppBreadcrumb from '../../components/layout/AppBreadcrumb';
import { memo, useCallback, useContext, useMemo,  useRef,  useState } from 'react';
import { useParams } from 'react-router';
import useObrasFetch from '../../hooks/useObrasFetch';
import {InputText} from 'primereact/inputtext';
import { toast } from 'react-toastify';
import { ficharService } from '../../services/reactivaService';
import AppContext from '../../contexts/AppContext';

import { ProgressSpinner } from 'primereact/progressspinner';


const FichajeTarjetaPage = () => {
	const { id } = useParams();
	const { obras } = useObrasFetch();
	const { user } = useContext(AppContext);
	const [fichando, setFichando] = useState(null);
	const [serialCard, setSerialCard] = useState(null);

	const obra = useMemo(() => obras?.find((o) => o.id === parseInt(id, 10)), [obras, id]);
 	const inputRef = useRef(null);
   
	const breadcrumb = useMemo(
		() => [
			{
				label: 'Inicio',
				icon: 'pi pi-home',
				url: '/',
			},
			{
				label: 'Obras',
				url: '/obras',
			},
			{
				label: obra?.nombre,
				url: `/obra/${id}`,
			},
			{
				label: 'Fichaje con tarjeta',
			},
		],
		[obra?.nombre, id]
	);

	const autoSelectInput = useCallback(() => {
		inputRef.current?.focus();
	},[]);

	const ficharAction = useCallback(async()=>{
			setTimeout(async()=>{
				try{	
					const res = await ficharService({
						usuarioCreacion: user?.id,
						fechaCreacion: new Date(),
						obra: id,
						tarjeta: serialCard,
					});
					if (res.error) {
						toast.error(res.mensaje, { position: 'top-center', autoClose: 1000 });						
					} else {
						toast.info(res.mensaje, { position: 'top-center', autoClose: 1000 });						
					}
					setSerialCard('');
					setFichando(false);
					autoSelectInput();
				} catch (e) {
					toast.error('Error fichando');
					setSerialCard(null);
					setFichando(false);
					autoSelectInput();
				}
			},1000);
	},[autoSelectInput, id, serialCard, user?.id]);

	const handleLecturaTarjeta = useCallback(
		async () => {
			if (!fichando) {	
				setFichando(true);
				ficharAction();
			}else{
				toast.warn('Espere por favor');
			}
		},
		[fichando, ficharAction]
	);

	return (
		<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
			<div className='w-full p-2' style={{ minHeight: '80vh' }}>
				<div className='w-full h-full'>
					<h2> Pase la tarjeta por el lector </h2>
					{fichando && <ProgressSpinner />}<br/>
					<i className='pi pi-credit-card mr-3' />
					<InputText
						autoFocus
						onBlur={e => {
							e.preventDefault();
							const target = e.currentTarget;
							setTimeout(function () {
								target.focus();
							}, 5);
						}}
						style={{							
							backgroundColor: '#eceff4',
							color: '#eceff4',
							border: '0px'}}
						value={serialCard}
						onChange={(e) => setSerialCard(e.target.value)}
						onKeyPress={(e) => {
							if (e.charCode === 13) {
								handleLecturaTarjeta();
							}
						}}
					/>
				</div>
			</div>
		</AppLayout>
	);
};

export default memo(FichajeTarjetaPage);
