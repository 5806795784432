import axios from 'axios';
import md5 from 'md5';
import { WS_URI } from './commonHelpers';

export const loginService = (user, password) => {
	const md5pass = md5(password);
	return axios.get(`${WS_URI}/login?user=${user}&password=${md5pass}`).then((response) => response?.data);
};

export const refreshTokenService = (user) => {
	return axios.get(`${WS_URI}/refreshToken?user=${user}`).then((response) => response?.data);
};


export const getObrasService = () => {
	return axios.get(`${WS_URI}/getAllObras`).then((response) => response?.data);
};

export const getObrasByUsuario = (id) => {
	return axios.get(`${WS_URI}/getObrasAsignadasByUsuario?id=${id}`).then((response) => response?.data);
};


export const getTiposGastosService = () => {
	return axios.get(`${WS_URI}/getTiposGastos`).then((response) => response?.data);
};


export const getGastosPersonalService = (id) => {
	return axios.get(`${WS_URI}/getMovimientosSaldoByPersona?id=${id}`).then((response) => response?.data);
};

export const analizaFicheroService =fichero =>{
return axios.post(`${WS_URI}/escaneaTicketCompra`, fichero).then((response) => response?.data);
}


export const saveGastoPersonalService = (gasto) => {
	return axios.post(`${WS_URI}/saveNuevoGastoPersonal`, gasto).then((response) => response?.data);
};

export const subeDocParteObraNuevoService = (parte) => {
	return axios.post(`${WS_URI}/subeDocParteObraNuevo`, parte).then((response) => response?.data);
};


export const saveParteTrabajoService = (parte) => {
	return axios.post(`${WS_URI}/saveParteTrabajo`, parte).then((response) => response?.data);
};;

export const ficharService = datos=>{
	return axios.post(`${WS_URI}/nuevoFichajePersona`, datos).then((response) => response?.data);
}

export const getPartesObraAbiertosHoyService = obra=>{
	return axios.get(`${WS_URI}/getPartesTrabajoByObraAbiertosHoy?obra=${obra}`).then((response) => response?.data);
}


export const getPartesTrabajoPendientesCumplimentar = obra =>{
return axios.get(`${WS_URI}/getPartesTrabajoPendientesCumplimentarByObra?obra=${obra}`).then((response) => response?.data);
}

export const getProveedoresService = () => {
	return axios.get(`${WS_URI}/getAllProveedores`).then((response) => response?.data);
};

export const getProveedoresObraService = (obra) => {
	return axios.get(`${WS_URI}/getProveedoresByObra?obra=${obra}`).then((response) => response?.data);
};

export const getPersonalProveedor = () => {
	return axios.get(`${WS_URI}/getAllPersonalObra`).then((response) => response?.data);
};

export const getPartesTrabajoProveedorHoy = (prov, fechaI, fechaF)=>{
	return axios.get(`${WS_URI}/getPartesTrabajoByProveedorYFechas?proveedor=${prov}&fechaI=${fechaI}&fechaF=${fechaF}`).then((response) => response?.data);
}