
import { useQuery } from '@tanstack/react-query';
import { getGastosPersonalService, getTiposGastosService } from '../services/reactivaService';
import { useContext } from 'react';
import AppContext from '../contexts/AppContext';

const useTipoGastoPersonalFetch = ()=>{
    const {user} = useContext(AppContext);
    
    const { data: tiposGastosPersonal } = useQuery({
        queryKey: ['tiposGastosPersonal'],
        queryFn: async () => await getTiposGastosService(),
    });

     const { data: gastosPersonal, isLoading: isLoadingGastosPersonal } = useQuery({
				queryKey: ['gastosPersonal', user?.personaInterno],
				queryFn: async () => await getGastosPersonalService(user?.personaInterno),
			});

    return {tiposGastosPersonal, gastosPersonal, isLoadingGastosPersonal};
};

export default useTipoGastoPersonalFetch;