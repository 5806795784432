import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import AppLayout from '../../components/layout/AppLayout';
import AppBreadcrumb from '../../components/layout/AppBreadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import useObrasFetch from '../../hooks/useObrasFetch';
import { toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';
import { useNavigate, useParams } from 'react-router';
import AppContext from '../../contexts/AppContext';
import { useQueryClient } from '@tanstack/react-query';
import { subeDocParteObraNuevoService } from '../../services/reactivaService';

const NuevoParteTrabajoPage = () => {
	const { user } = useContext(AppContext);
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [datos, setDatos] = useState(null);

	const [, setFile] = useState(null);
	const [file64, setFile64] = useState(null);
	const fRef = useRef();
	const clicked = useRef();

	const resizeFile = (file, format) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				300,
				400,
				'JPEG',
				70,
				0,
				(uri) => {
					resolve(uri);
				},
				format
			);
		});

	const { id } = useParams();
	const { obras } = useObrasFetch();

	const obra = useMemo(() => obras?.find((o) => o.id === parseInt(id, 10)), [obras, id]);

	const breadcrumb = useMemo(
		() => [
			{
				label: 'Inicio',
				icon: 'pi pi-home',
				url: '/',
			},
			{
				label: 'Obras',
				url: '/obras',
			},
			{
				label: obra?.nombre,
				url: `/obra/${id}`,
			},
			{
				label: 'Enviar parte de trabajo',
			},
		],
		[obra?.nombre, id]
	);

	const changeAttr = useCallback(
		(attr, val) => {
			const newD = { ...datos };
			newD[attr] = val;
			setDatos(newD);
		},
		[datos]
	);

	const saveParte = useCallback(async () => {
		if (!datos?.fecha) {
			toast.error('Todos los campos son obligatorios.');
		} else {
			const res = await subeDocParteObraNuevoService({
				...datos,
				obra: id,
				documento: file64?.substring(file64?.indexOf('base64,') + 7, file64?.length),
				fechaCreacion: new Date(),
				usuarioCreacion: user?.id,
			});
			if (res.error) {
				toast.error(res.message);
			} else {
				queryClient.invalidateQueries({ queryKey: ['partesTrabajo', user?.personaInterno] });
				toast('Parte guardado correctamente')
				navigate(`/obra/${id}`);
			}
		}
	}, [datos, file64, id, navigate, queryClient, user?.id, user?.personaInterno]);

	const handleFileChange = useCallback(async (e) => {
		if (e.target.files) {
			var reader = new FileReader();
			var file = e.target.files[0];
			const compressedfile = await resizeFile(file, 'file');
			setFile(compressedfile);
			const compressedfileb64 = await resizeFile(file, 'base64');
			setFile64(compressedfileb64);
			reader.onload = function (upload) {
				const binaryStr = upload.target.result;
				setFile64(binaryStr);
			};
			reader.readAsDataURL(file);
		}
	}, []);

	useEffect(() => {
		if (fRef.current && !clicked.current) {
			clicked.current = true;
			fRef.current.click();
		}
	}, []);

	return (
		<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
				<div className=' formgrid m-2 w-full surface-card border-round-sm'>
					<div className='field mb-0 col-12'>
						<label for='nombre' className='font-medium text-900 text-xs'>
							Imagen parte de trabajo
						</label>
						<input id='file' type='file' onChange={handleFileChange} ref={fRef} accept='image/*' capture />
					</div>
					<div className='field mb-0 col-12'>
						<label for='nombre' className={`font-medium text-900 text-xs ${!datos?.fecha ? 'text-red-400' : ''}`}>
							Fecha
						</label>
						<Calendar
							locale='es'
							value={datos?.fecha}
							onChange={(e) => changeAttr('fecha', e.value)}
							className=' w-full mb-0  p-0 text-sm'
							panelClassName='text-sm'
						/>
					</div>
					<div className='col-12 mt-2 align-content-end'>
						<Button label='Guardar' className='text-sm align-items-end mr-2' onClick={saveParte} />
						<Button label='Atrás' className='text-sm align-items-end' onClick={() => navigate( `/obras/${id}`)} />
					</div>
				</div>
		</AppLayout>
	);
};

export default memo(NuevoParteTrabajoPage);
