import { memo, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import useObrasFetch from '../../hooks/useObrasFetch';
import AppLayout from '../../components/layout/AppLayout';
import AppBreadcrumb from '../../components/layout/AppBreadcrumb';
import FormularioParteObra from '../../components/obras/FormularioParteObra';

const ParteDesplazamiento = () => {
	const { id } = useParams();
	const { obras } = useObrasFetch();

	const obra = useMemo(() => obras?.find((o) => o.id === parseInt(id, 10)), [obras, id]);
	const navigate = useNavigate();

	const breadcrumb = useMemo(
		() => [
			{
				label: 'Inicio',
				icon: 'pi pi-home',
				url: '/',
			},
			{
				label: 'Obras',
				url: '/obras',
			},
			{
				label: obra?.nombre,
				url: `/obra/${id}`,
			},
			{
				label: 'Parte desplazamiento',
			},
		],
		[obra?.nombre, id]
	);

    const navigateObras = useCallback(()=>{
        navigate(`/obra/${id}`)
    },[id, navigate]);

	return (
		<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
			<div className='w-full p-2' style={{ maxHeight: '100vh' }}>
				<div className='surface-card shadow-2 border-round p-4'>
					<div className='flex justify-content-between align-items-center mb-5'>
						<span class='text-xl text-900 font-medium'>Parte desplazamiento (Solo reactiva)</span>
						<div></div>
					</div>
					<FormularioParteObra parte={{obra: parseInt(id,10), proveedor:56}} desplazamiento onClose={navigateObras} />
				</div>
			</div>
		</AppLayout>
	);
};
export default memo(ParteDesplazamiento);
