import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';

const ListadoElement = ({ icono, titulo, subtitulo1, subtitulo2, bgcolor, badge, badgeColor, right }) => (
	<li
		className={classNames(
			'flex flex-column md:flex-row md:align-items-center md:justify-content-between p-3 border-1 mb-1  border-gray-500 border-round-lg',
			bgcolor
		)}
	>
		<div>
			<div className=' mt-0 mb-0 text-left'>
				{icono && (
					<span className='inline-flex justify-content-center align-items-center w-2rem mr-1 h-2rem border-circle border-1 border-gray-200'>
						<i className={icono}></i>
					</span>
				)}
				{titulo && <span className=' font-bold '>{titulo}</span>}
			</div>
			{subtitulo1 && <p class=' mt-1 mb-0 text-left text-xs'>
				{subtitulo1}
				<span class='font-medium ml-4'>{subtitulo2}</span>
			</p>}
		</div>
		<div class='flex align-items-center justify-content-between md:justify-content-end mt-1 md:mt-0'>
			<span className={classNames('font-bold text-sm py-1 px-2 border-round-lg', badgeColor)}>{badge}</span>
			<div className={classNames('text-right ml-3')}>{right}</div>
		</div>
	</li>
);

ListadoElement.propTypes = {
    icono: PropTypes.string,
	titulo: PropTypes.string,
	subtitulo1: PropTypes.string,
	subtitulo2: PropTypes.string,
	bgcolor: PropTypes.string,
	badge: PropTypes.string,
	badgeColor: PropTypes.string,
	right: PropTypes.any,
};

export default ListadoElement;