import { memo, useMemo } from "react";
import { useParams } from "react-router";
import useObrasFetch from "../../hooks/useObrasFetch";
import AppLayout from "../../components/layout/AppLayout";
import AppBreadcrumb from "../../components/layout/AppBreadcrumb";
import useProveedoresFetch from "../../hooks/useProveedoresFetch";
import NavItem from "../../components/nav/NavItem";

const FichajeProveedores = ()=>{
    const { id } = useParams();
    const { obras } = useObrasFetch();
    const obra = useMemo(() => obras?.find((o) => o.id === parseInt(id, 10)), [obras, id]);
    const { proveedoresObra, proveedores } = useProveedoresFetch({ obra: id });

    const breadcrumb = useMemo(
			() => [
				{
					label: 'Inicio',
					icon: 'pi pi-home',
					url: '/',
				},
				{
					label: 'Obras',
					url: '/obras',
				},
				{
					label: obra?.nombre,
					url: `/obra/${id}`,
				},
				{
					label: 'Fichaje proveedor',
				},
			],
			[obra?.nombre, id]
		);

        const datos = useMemo(
					() =>
						proveedoresObra?.map((p) => ({
							...p,
							proveedorNombre: proveedores?.find((o) => o.id === p.proveedor)?.nombre,
						})),
					[proveedoresObra, proveedores]
				);
        

        return (
					<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
						<div className='w-full p-2' style={{ maxHeight: '100vh' }}>
							<div
								className='flex flex-column lg:flex-row gap-3 justify-content-between lg:align-items-center border-primary py-2'
								style={{ borderBottom: '7px solid' }}
							>
								<span className='text-900 font-medium text-2xl'> Seleccione opción </span>
							</div>
							<ul className='list-none p-1'>
								{datos?.map((o) => (
									<li
										key={o.id}
										className='border-bottom-1 surface-border py-3md:align-items-center md:justify-content-between'
									>
										<NavItem title={o.proveedorNombre} icon='pi pi-briefcase' url={`/fichajeProveedorObra/${id}/${o.proveedor}`} />
									</li>
								))}
							</ul>
						</div>
					</AppLayout>
				);

	
};

export default memo(FichajeProveedores);