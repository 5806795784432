import NavItem from '../../components/nav/NavItem';
import AppLayout from '../../components/layout/AppLayout';
import AppBreadcrumb from '../../components/layout/AppBreadcrumb';
import { memo, useMemo } from 'react';
import { useParams } from 'react-router';
import useObrasFetch from '../../hooks/useObrasFetch';

const ObrasMainPage = () => {
	const {id} = useParams();
	const {obras} = useObrasFetch();

	const obra = useMemo(()=>obras?.find(o=>o.id===parseInt(id,10)),[obras,id]);

	const breadcrumb = useMemo(
		() => [
			{
				label: 'Inicio',
				icon: 'pi pi-home',
				url: '/',
			},
			{
				label: 'Obras',
				url: '/obras'
			},
			{
				label: obra?.nombre,
			},
		],
		[obra?.nombre]
	);
	return (
		<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
			<div className='w-full p-2' style={{ maxHeight: '100vh' }}>
				<div
					className='flex flex-column lg:flex-row gap-3 justify-content-between lg:align-items-center border-primary py-2'
					style={{ borderBottom: '7px solid' }}
				>
					<span className='text-900 font-medium text-2xl'> Seleccione opción </span>
				</div>
				<ul className='list-none p-1'>
					<li className='border-bottom-1 surface-border py-1 md:align-items-center md:justify-content-between'>
						<NavItem title='Fichaje con tarjeta' icon='pi pi-credit-card' url={`/fichajesTarjeta/${id}`} />
					</li>
					<li className='border-bottom-1 surface-border py-1 md:align-items-center md:justify-content-between'>
						<NavItem title='Fichaje proveedores' icon='pi pi-user' url={`/fichajesProveedor/${id}`} />
					</li>
					<li className='border-bottom-1 surface-border py-1 md:align-items-center md:justify-content-between'>
						<NavItem title='Enviar parte diario' icon='pi pi-file' url={`/nuevoParteTrabajo/${id}`} />
					</li>
					{/* <li className='border-bottom-1 surface-border py-3 md:align-items-center md:justify-content-between'>
						<NavItem title='Listado partes diarios' icon='pi pi-folder' url='/listadoParteTrabajo' />
					</li> */}
					<li className='border-bottom-1 surface-border py-1 md:align-items-center md:justify-content-between'>
						<NavItem title='Personal trabajando actualmente' icon='pi pi-sun' url={`/personalTrabajando/${id}`} />
					</li>
					<li className='border-bottom-1 surface-border py-1 md:align-items-center md:justify-content-between'>
						<NavItem title='Partes pendientes' icon='pi pi-exclamation-triangle' url={`/partesPendientes/${id}`} />
					</li>
					<li className='border-bottom-1 surface-border py-1 md:align-items-center md:justify-content-between'>
						<NavItem title='Parte desplazamiento' icon='pi pi-car' url={`/parteDesplazamiento/${id}`} />
					</li>
				</ul>
			</div>
		</AppLayout>
	);
};

export default memo(ObrasMainPage);
