import PropTypes from  'prop-types';

import reactiva from '../../assets/reactiva.png';
const AppLayout = ({children, breadcrumb}) => {
	return (
		<>
			<div className='block-content min-h-screen max-h-screen custom-scrollbar'>
				<div className='ng-star-inserted' style={{ height: '100vh' }}>
					<div className='bg-primary'>
						<img src={reactiva} alt=' login' height='50' className='mb-1 br-1' />
					</div>
					{breadcrumb}
					<div className=' flex relative lg:static surface-ground' style={{ height: 'calc(100vh - 120px)', overflowY: 'scroll' }}>
						{/* <div className='min-h-screen flex relative flex-auto'>{children}</div> */}
						{children}
					</div>
				</div>
			</div>
		</>
	);
};

AppLayout.propTypes={
	children: PropTypes.any,
	breadcrumb: PropTypes.any
}
export default AppLayout;