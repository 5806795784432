import { memo,  useMemo } from "react";
import useProveedoresFetch from "../../hooks/useProveedoresFetch";
import useObrasFetch from "../../hooks/useObrasFetch";
import { useParams } from "react-router";
import AppLayout from "../../components/layout/AppLayout";
import AppBreadcrumb from "../../components/layout/AppBreadcrumb";
import usePersonalProveedorFetch from "../../hooks/usePersonalProveedorFetch";
import ListadoElement from "../../components/components/ListadoElement";
import usePartesTrabajoFetch from "../../hooks/usePartesTrabajoFetch";
import EstadoPartesObra from "../../components/obras/EstadoPartesObra";
import BotonPartesObra from "../../components/obras/BotonPartesObra";
import orderBy from 'lodash/orderBy';

const FichajeProveedorObra = ()=>{
const { id, prov } = useParams();
const { obras } = useObrasFetch();
const {proveedores} = useProveedoresFetch();
const obra = useMemo(() => obras?.find((o) => o.id === parseInt(id, 10)), [obras, id]);
const proveedor = useMemo(() => proveedores?.find((o) => o.id === parseInt(prov, 10)), [proveedores, prov]);
const {allPersonalProveedor} = usePersonalProveedorFetch();
const { partesTrabajoObraProveedorHoy } = usePartesTrabajoFetch({ obra: id, proveedor: prov });

const breadcrumb = useMemo(
	() => [
		{
			label: 'Inicio',
			icon: 'pi pi-home',
			url: '/',
		},
		{
			label: 'Obras',
			url: '/obras',
		},
		{
			label: obra?.nombre,
			url: `/obra/${id}`,
		},
		{
			label: proveedor?.nombre,
			url: `/fichajeProveedor/${id}`,
		},
		{
			label:'Fichajes'
        }
	],
	[obra?.nombre, id, proveedor?.nombre]
);

const datos = useMemo(
	() =>
		orderBy(allPersonalProveedor
			?.filter((a) => a.proveedor === parseInt(prov, 10))
			?.filter((a) => a.habilitado)
			?.map((p) => ({
				...p,
				partes: partesTrabajoObraProveedorHoy?.filter((pp) => p.id === pp.persona),
			})),'nombre'),
	[allPersonalProveedor, partesTrabajoObraProveedorHoy, prov]
);

	
	
    return (
			<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
				<div className='w-full p-2' style={{ maxHeight: '100vh' }}>
					<div
						className='flex flex-column lg:flex-row gap-3 justify-content-between lg:align-items-center border-primary py-2'
						style={{ borderBottom: '7px solid' }}
					>
						<span className='text-900 font-medium text-2xl'> Seleccione opción </span>
					</div>
					<ul className='list-none p-1  bg-white border-round-lg'>
						{datos?.map((d) => (
							<ListadoElement
								icon='pi pi-user'
								titulo={d?.nombre}
								bgcolor='bg-blue-50'
								subtitulo1={<EstadoPartesObra datos={d} />}
								right={
									<>
										<BotonPartesObra datos={d} obra={obra?.id} />
									</>
								}
							/>
						))}
					</ul>
				</div>
			</AppLayout>
		);


};


export default memo(FichajeProveedorObra);