
import AppLayout from '../../components/layout/AppLayout';
import AppBreadcrumb from '../../components/layout/AppBreadcrumb';
import { memo,  useMemo, useState } from 'react';
import useTipoGastoPersonalFetch from '../../hooks/useTipoGastoPersonal';
import useObrasFetch from '../../hooks/useObrasFetch';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import ListadoElement from '../../components/components/ListadoElement';

const DesplazaMainPage = () => {
	const breadcrumb = useMemo(
		() => [
			{
				label: 'Inicio',
				icon: 'pi pi-home',
				url: '/',
			},
			{
				label: 'Desplaza',
                url: '/desplaza'
			},
			{
				label: 'Mis gastos',
			},
		],
		[]
	);
//	const [estado, setEstado] = useState();
	const { gastosPersonal,  tiposGastosPersonal } = useTipoGastoPersonalFetch();
	const [fecha, setFecha] = useState(null);
	const [tipo, setTipo] = useState(null);
	const { obras } = useObrasFetch();

	const datos = useMemo(
		() =>
			gastosPersonal
				?.map((po) => ({
					...po,
					obraNombre: obras?.find((p) => p.id === po.obra)?.nombre,
					tipoGastoNombre: !po.ingreso? tiposGastosPersonal?.find((p) => p.id === po.tipoGasto)?.nombre: 'Ingreso',
				}))
				?.filter((g) => (tipo ? g.tipoGasto === tipo : true))
				// ?.filter((g) => (estado ? g.estado === estado : true))
				?.filter((g) => (fecha ? g.fecha >= fecha[0] && g?.fecha <= fecha[1] : true)),
		[gastosPersonal, obras, tiposGastosPersonal, tipo,  fecha]
	);
	


    const total = useMemo(()=>{
        let total=0;
        gastosPersonal?.forEach(g=>{
            total = g.ingreso ? total + g.importe : total - g.importe;
        });
        return total;
    },[gastosPersonal]);

	

	return (
		<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
			<div className='w-full p-2' style={{ maxHeight: '100vh' }}>
				<div className='grid p-1'>
					<div className='col-6'>
						Tipo <br />
						<Dropdown
							value={tipo}
							onChange={(e) => setTipo(e.value)}
							options={tiposGastosPersonal?.map((m) => ({ label: m.nombre, value: m.id }))}
							className=' w-full p-0 text-sm'
							panelClassName='text-sm'
							filter
							showClear
						/>
					</div>
					<div className='col-6 '>
						Fecha desde <br />
						<Calendar
							className='w-full p-0 text-sm'
							value={fecha}
							locale='es'
							selectionMode='range'
							readOnlyInput
							hideOnRangeSelection
							onChange={(e) => setFecha(e.target.value)}
						/>
					</div>

					<div className='col-12'>
						<h4 className={total < 0 ? 'text-red-600 p-0 m-0' : 'text-green-600 p-0 m-0'}> Saldo actual: {total} € </h4>
						<ul className='list-none p-0 m-0'>
							{datos?.map((d) => (
								<ListadoElement
								icono={d.ingreso?'pi pi-euro': 'pi pi-credit-card'}
									titulo={d.obraNombre ?? 'Ingreso'}
									subtitulo1={d.tipoGastoNombre}
									subtitulo2={d.observaciones}
									bgcolor={(d.importe>0 ? (d.ingreso?'bg-green-100': 'bg-yellow-100'):'bg-red-100')}
									right={
										<>
											<span class=' font-bold'>{d?.importe} €</span>
											<span> {new Date(d?.fecha)?.toLocaleDateString()}</span>
										</>
									}
								/>
							))}
						</ul>
						
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default memo(DesplazaMainPage);
