import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import AppLayout from '../../components/layout/AppLayout';
import AppBreadcrumb from '../../components/layout/AppBreadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import {BlockUI} from 'primereact/blockui';
import { InputNumber } from 'primereact/inputnumber';
import useObrasFetch from '../../hooks/useObrasFetch';
import useTipoGastoPersonalFetch from '../../hooks/useTipoGastoPersonal';
import { analizaFicheroService, saveGastoPersonalService } from '../../services/reactivaService';
import { toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';
import { useNavigate } from 'react-router';
import AppContext from '../../contexts/AppContext';
import { useQueryClient } from '@tanstack/react-query';

const NuevoGastoForm = () => {
	const {user} = useContext(AppContext);
	const navigate = useNavigate();
	const queryClient = useQueryClient();
    const [datos, setDatos] = useState(null);
    
    const { obras, obrasUsuario } = useObrasFetch({});
    const {tiposGastosPersonal} = useTipoGastoPersonalFetch();
	
    const [file, setFile] = useState(null);
    const [file64, setFile64] = useState(null);
	const [analizando, setAnalizando] = useState(null);
    const fRef = useRef();
	const clicked = useRef();

	const resizeFile = (file, format) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				300,
				400,
				'JPEG',
				70,
				0,
				(uri) => {
					resolve(uri);
				},
				format
			);
		});


	const breadcrumb = useMemo(
		() => [
			{
				label: 'Inicio',
				icon: 'pi pi-home',
				url: '/',
			},
			{
				label: 'Desplaza',
				url: '/desplaza'
			},
			{
				label: 'Nuevo Gasto',
			},
		],
		[]
	);

    const obrasF = useMemo(
			() => obras?.filter((o) => obrasUsuario?.find((op) => op.obra === o.id)),
			[obras, obrasUsuario]
		);

     const changeAttr = useCallback(
            (attr, val) => {
                const newD = { ...datos };
                newD[attr] = val;
                setDatos(newD);
            },
            [datos]
        );

    const saveGasto = useCallback(async ()=>{

        if (!datos?.obra || !datos?.importe || !datos?.proveedor || !datos?.tipoGasto || !file64 || !datos?.observaciones) {
					toast.error('Todos los campos son obligatorios.');
				} else {
					const res = await saveGastoPersonalService({
						...datos,
						imagen: file64?.substring(file64?.indexOf('base64,')+7,file64?.length),
						persona: user?.personaInterno,
						estado: 'pendiente',
						fechaCreacion: new Date(),
						usuarioCreacion: user?.id
					});
					if (res.error) {
						toast.error(res.mensaje);
					} else {
						queryClient.invalidateQueries({ queryKey: ['gastosPersonal', user?.personaInterno] });
						toast('Gasto guardado correctamente');
						navigate('/listadoGastos');
					}
				}
    },[datos, file64, navigate, queryClient, user?.id, user?.personaInterno]);

   
    const handleFileChange = useCallback(async (e) => {
        if (e.target.files) {
            var reader = new FileReader();
            var file = e.target.files[0];
			const compressedfile = await resizeFile(file, 'file');
			setFile(compressedfile);
			const compressedfileb64 = await resizeFile(file, 'base64');
			setFile64(compressedfileb64);
            reader.onload = function (upload) {
                const binaryStr = upload.target.result;
                setFile64(binaryStr);
            };
            reader.readAsDataURL(file);
        }
    }, []);

    useEffect(()=>{
        if (fRef.current && !clicked.current){			
		   clicked.current=(true);
           fRef.current.click();
        }
    },[]);

    const analizaFichero = useCallback(async ()=>{
         const formData = new FormData();
		formData.append('file', file);
		try{
			setAnalizando(true);
			const result = await analizaFicheroService(formData);
			if (result?.documents?.length){
				const docD = result.documents[0]?.fields;
				setDatos({
					proveedor: `${docD?.MerchantName?.value} ${docD?.MerchantAddress?.value ?? ''}`,
					importe: docD?.Total?.value,
					fecha: docD?.TransactionDate?.value? new Date(docD?.TransactionDate?.value): new Date()
				});

			}
			setAnalizando(false);
		}catch(e){
			toast.error('No puedo analizar el documento, por favor introduzca los datos a mano.')
		}
		setAnalizando(false);
    },[file]);
    
    useEffect(()=>{
       if (file){
         analizaFichero();
       }
    },[file, analizaFichero]);


	return (
		<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
			<BlockUI blocked={analizando} template={<i className='pi pi-spin pi-spinner' style={{ fontSize: '3rem' }} />}>
				<div className='grid formgrid m-2  surface-card border-round-sm'>
					<div className='field mb-0 col-12'>
						<label for='nombre' className='font-medium text-900 text-xs'>
							Ticket (Subir primero, se analizará para obtener datos)
						</label>
						<input id='file' type='file' onChange={handleFileChange} ref={fRef} accept='image/*' capture />
					</div>
					<div className='field mb-0 col-12'>
						<label for='nombre' className={`font-medium text-900 text-xs ${!datos?.fecha ? 'text-red-400' : ''}`}>
							Fecha
						</label>
						<Calendar
							locale='es'
							value={datos?.fecha}
							onChange={(e) => changeAttr('fecha', e.value)}
							className=' w-full mb-0  p-0 text-sm'
							panelClassName='text-sm'
						/>
					</div>
					<div className='field mb-0 col-12'>
						<label for='nombre' className={`font-medium text-900 text-xs ${!datos?.obra ? 'text-red-400' : ''}`}>
							Obra
						</label>
						<Dropdown
							value={datos?.obra}
							onChange={(e) => changeAttr('obra', e.value)}
							options={obrasF?.map((m) => ({ label: m.nombre, value: m.id }))}
							className=' w-full mb-0  p-0 text-sm'
							panelClassName='text-sm'
							filter
						/>
					</div>
					<div className='field mb-0 col-12'>
						<label for='nombre' className={`font-medium text-900 text-xs ${!datos?.proveedor ? 'text-red-400' : ''}`}>
							Proveedor
						</label>
						<InputText
							className='p-inputtext w-full mb-0  p-1 text-sm'
							value={datos?.proveedor}
							onChange={(e) => changeAttr('proveedor', e.target.value)}
						/>
					</div>
					<div className='field mb-0 col-12'>
						<label for='nombre' className={`font-medium text-900 text-xs ${!datos?.importe ? 'text-red-400' : ''}`}>
							Importe
						</label>
						<InputNumber
							locale='es-ES'
							minFractionDigits={2}
							className='w-full mb-0  p-0 text-sm'
							value={datos?.importe}
							onChange={(e) => changeAttr('importe', e.value)}
						/>
					</div>
					<div className='field mb-0 col-12'>
						<label for='nombre' className={`font-medium text-900 text-xs ${!datos?.tipoGasto ? 'text-red-400' : ''}`}>
							Tipo
						</label>
						<Dropdown
							value={datos?.tipoGasto}
							onChange={(e) => changeAttr('tipoGasto', e.value)}
							options={tiposGastosPersonal?.map((m) => ({ label: m.nombre, value: m.id }))}
							className=' w-full mb-0  p-0 text-sm'
							panelClassName='text-sm'
						/>
					</div>
					<div className='field mb-0 col-12'>
						<label
							for='nombre'
							className={`font-medium text-900 text-xs ${!datos?.tipoDocumento ? 'text-red-400' : ''}`}
						>
							Documento
						</label>
						<Dropdown
							value={datos?.tipoDocumento}
							onChange={(e) => changeAttr('tipoDocumento', e.value)}
							options={[
								{ label: 'Ticket', value: 'TICKET' },
								{ label: 'Albarán', value: 'ALBARAN' },
								{ label: 'Factura', value: 'FACTURA' },
							]}
							className=' w-full mb-0  p-0 text-sm'
							panelClassName='text-sm'
						/>
					</div>
					<div className='field mb-0 col-12'>
						<label
							for='nombre'
							className={`font-medium text-900 text-xs ${!datos?.observaciones ? 'text-red-400' : ''}`}
						>
							Observaciones
						</label>
						<InputText
							className='p-inputtext w-full mb-0  p-1 text-sm'
							value={datos?.observaciones}
							onChange={(e) => changeAttr('observaciones', e.target.value)}
						/>
					</div>

					<div className='col-12 mt-2 align-content-end'>
						<Button label='Guardar' className='text-sm align-items-end mr-2' onClick={saveGasto} />
						<Button label='Atrás' className='text-sm align-items-end' onClick={() => navigate('/desplaza')} />
					</div>
				</div>
			</BlockUI>
		</AppLayout>
	);
};

export default memo(NuevoGastoForm);
