import RequireAuth from './components/auth/RequireAuth';
import LoginPage from './pages/LoginPage';
import { Route, Routes } from 'react-router';
import InicioPage from './pages/InicioPage';
import { useAuthUser } from './hooks/useAuthUser';
import DesplazaMainPage from './pages/desplaza/DesplazaMainPage';
import ObrasMainPage from './pages/obras/ObrasMainPage';
import NuevoGastoForm from './pages/desplaza/NuevoGastoForm';
import ListadoGastosPage from './pages/desplaza/ListadoGastosPage';
import ObrasPage from './pages/obras/ObrasPage';
import FichajeTarjetaPage from './pages/obras/FichajeTarjetaPage';
import NuevoParteTrabajoPage from './pages/obras/NuevoParteTrabajoPage';
import PersonalObraHoy from './pages/obras/PersonalObraHoy';
import FichajeProveedores from './pages/obras/FichajeProveedores';
import FichajeProveedorObra from './pages/obras/FichajeProveedorObra';
import CierraParteObra from './pages/obras/CierraParteObra';
import PartesPendientesPage from './pages/obras/PartesPendientesPage';
import CumplimentarPartePage from './pages/obras/CumplimentarPartePage';
import ParteDesplazamiento from './pages/obras/ParteDesplazamiento';

const Main = () => {
	useAuthUser();

	return (
		<Routes>
			<Route exact path='/login' element={<LoginPage />} />
			<Route
				exact
				path='/'
				element={
					<RequireAuth>
						<InicioPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/desplaza'
				element={
					<RequireAuth>
						<DesplazaMainPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/obras'
				element={
					<RequireAuth>
						<ObrasPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/obra/:id'
				element={
					<RequireAuth>
						<ObrasMainPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/nuevoGasto'
				element={
					<RequireAuth>
						<NuevoGastoForm />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/listadoGastos'
				element={
					<RequireAuth>
						<ListadoGastosPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/fichajesTarjeta/:id'
				element={
					<RequireAuth>
						<FichajeTarjetaPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/nuevoParteTrabajo/:id'
				element={
					<RequireAuth>
						<NuevoParteTrabajoPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/personalTrabajando/:id'
				element={
					<RequireAuth>
						<PersonalObraHoy />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/fichajesProveedor/:id'
				element={
					<RequireAuth>
						<FichajeProveedores />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/fichajeProveedorObra/:id/:prov'
				element={
					<RequireAuth>
						<FichajeProveedorObra />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/cierraParte/:id/:prov/:idParte'
				element={
					<RequireAuth>
						<CierraParteObra />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/partesPendientes/:id'
				element={
					<RequireAuth>
						<PartesPendientesPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/cumplimentarParte/:id/:idParte'
				element={
					<RequireAuth>
						<CumplimentarPartePage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/parteDesplazamiento/:id'
				element={
					<RequireAuth>
						<ParteDesplazamiento />
					</RequireAuth>
				}
			/>
		</Routes>
	);
};

export default Main;
