import { memo, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'primereact/button';
import { useQueryClient } from '@tanstack/react-query';
import { saveParteTrabajoService } from '../../services/reactivaService';
import { toast } from 'react-toastify';
import AppContext from '../../contexts/AppContext';
import { useNavigate } from 'react-router';

const BotonPartesObra = ({datos, obra}) => {
    const queryClient = useQueryClient();
    
    const partesObra = useMemo(() => datos?.partes?.filter((d) => d.obra === obra), [datos, obra]);

	const partesOtraObra = useMemo(() => datos?.partes?.filter((d) => d.obra !== obra), [datos, obra]);

    const {user} = useContext(AppContext);

    const navigate = useNavigate();

    const ficharEntrada = useCallback(async ()=>{
        const nuevoParte = {
            obra: obra,
            horaEntrada: new Date(),
            persona: datos?.id,
            proveedor: datos?.proveedor,
            desplazamiento: 0,
            fechaCreacion: new Date(),
            usuarioCreacion: user?.id
        };
        const res = await saveParteTrabajoService(nuevoParte);
        if (res.error){
            toast.error(res.mensaje);
        }else{
            queryClient.invalidateQueries({ queryKey: ['partesTrabajoAbiertos'] });
            queryClient.invalidateQueries({ queryKey: ['partesTrabajoObraProveedorHoy'] });
			toast('Fichaje guardado correctamente');
        }
    },[datos?.id, datos?.proveedor, obra, queryClient, user?.id]);

    const cierraParte = useCallback(()=>{
        const parteACerrar= partesObra?.find(p=>!p.horaSalida);
        navigate(`/cierraParte/${parteACerrar?.obra}/${datos?.proveedor}/${parteACerrar?.id}`);
    },[datos?.proveedor, navigate, partesObra]);
    

    if (datos?.partes?.length===0){
        return (
					<Button icon='pi pi-sign-in' label='Marcar entrada' onClick={ficharEntrada} style={{ width: '150px' }} />
				);
    }
   

    if (partesObra?.find(p=>!p.horaSalida)){
        return <Button icon='pi pi-sign-out' label='Marcar salida' severity='info' 
            style={{ width: '150px' }} onClick={cierraParte} />;
    }
    if (partesOtraObra?.find(p=>!p.horaSalida)){
         return (
						<Button
							icon='pi pi-clock'
							disabled
							label='Entrada en otra obra'
							severity='danger'
							style={{ width: '150px' }}
						/>
					);
    }
    return <Button icon='pi pi-clock' label='Marcar entrada' style={{ width: '150px' }} onClick={ficharEntrada}/>;
};

BotonPartesObra.propTypes = {
	datos: PropTypes.object,
    obra: PropTypes.number
};

export default memo(BotonPartesObra);
