import { memo, useCallback, useMemo } from 'react';
import useProveedoresFetch from '../../hooks/useProveedoresFetch';
import useObrasFetch from '../../hooks/useObrasFetch';
import { useNavigate, useParams } from 'react-router';
import AppLayout from '../../components/layout/AppLayout';
import AppBreadcrumb from '../../components/layout/AppBreadcrumb';
import usePartesTrabajoFetch from '../../hooks/usePartesTrabajoFetch';
import FormularioParteObra from '../../components/obras/FormularioParteObra';

const CierraParteObra = () => {
    const {obras} = useObrasFetch();
	const { id, idParte, prov } = useParams();
    const obra = useMemo(() => obras?.find((o) => o.id === parseInt(id, 10)), [obras, id]);
    const { proveedores } = useProveedoresFetch();
	const proveedor = useMemo(() => proveedores?.find((o) => o.id === parseInt(prov, 10)), [proveedores, prov]);
	
	const { partesTrabajoObraProveedorHoy } = usePartesTrabajoFetch({ obra: id, proveedor: prov });
    const parte = partesTrabajoObraProveedorHoy?.find(p=>p.id === parseInt(idParte,10),[partesTrabajoObraProveedorHoy, idParte])

    const navigate = useNavigate();

	const breadcrumb = useMemo(
		() => [
			{
				label: 'Inicio',
				icon: 'pi pi-home',
				url: '/',
			},
			{
				label: 'Obras',
				url: '/obras',
			},
			{
				label: obra?.nombre,
				url: `/obra/${id}`,
			},
			{
				label: proveedor?.nombre,
				url: `/fichajeProveedor/${id}/${prov}`,
			},
			{
				label: 'Fichajes',
			},
		],
		[obra?.nombre, id, proveedor?.nombre,prov]
	);

    const navigateListado = useCallback(()=>{
        navigate(`/fichajeProveedorObra/${id}/${prov}`);
    },[id, navigate, prov]);

	
	return (
		<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
			<div className='w-full p-2' style={{ maxHeight: '100vh' }}>
				<div
					className='flex flex-column lg:flex-row gap-3 justify-content-between lg:align-items-center border-primary py-2'
					style={{ borderBottom: '7px solid' }}
				>
					<span className='text-900 font-medium text-2xl'> Seleccione opción </span>
				</div>
				<FormularioParteObra parte={parte} onClose={navigateListado} />
			</div>
		</AppLayout>
	);
};

export default memo(CierraParteObra);
