import { Navigate } from 'react-router';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import AppContext from '../../contexts/AppContext';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { refreshTokenService } from '../../services/reactivaService';
import { toast } from 'react-toastify';

const RequireAuth = ({ children,roles }) => {
	const { setItem } = useLocalStorage();
	const { setUser,user } = useContext(AppContext);

	

	const isValidUser = useMemo(() => {
		if (user?.token) {
			let decodedToken = jwtDecode(user?.token);
			let currentDate = new Date();
			// JWT exp is in seconds
			if (decodedToken.exp * 1000 < currentDate.getTime()) {
				axios.defaults.headers.common = null;
				return false;
			} else {
				
				console.log('seteando token', user?.token);
				axios.defaults.headers.common = { Authorization: `${user?.token}` };
				return true;
			}
		}
		return false;
	}, [user]);

	const intervalRef = useRef();

	const getToken = useCallback(() =>{
		refreshTokenService(user?.userName).then(data =>{
			setItem('user', JSON.stringify(data));
			setUser(data);
		}).catch(()=>{
			toast.error("Sesión expirada");
			setUser(null);
			setItem('user', null);
		});
	} , [setItem, setUser, user]);

	useEffect(() => {
		if (isValidUser){
			const interval = setInterval(() => getToken(), 3*60*1000);
			intervalRef.current = interval;
			return () => clearInterval(interval);
		}
	}, [getToken, isValidUser]);

	if (!isValidUser) {
		return <Navigate to='/login' replace />;
	}
	if (user?.rol===2){
		return <div> NO TIENE PERMISO</div>;
	}
	
	return user ? children : <Navigate to='/login' replace />;
};

RequireAuth.propTypes = {
	children: PropTypes.any,
	roles: PropTypes.array
};
export default RequireAuth;
