import moment from 'moment';
//produccion
export const WS_URI = 'https://ws.reactiva.es/reactivaGestionWS';
//desarrollo
//export const WS_URI = 'http://192.168.1.169:8082/reactivaGestionWS';
//export const WS_URI = 'http://vb.no-ip.org:8087/reactivaGestionWS';

export const calculaDias = (fechaFin, fechaInicio) => {
	const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
	return Math.round(Math.abs((fechaFin - fechaInicio) / oneDay));
};

export const sumaHoras = (fecha, horas) => {
	fecha.setHours(fecha.getHours() + horas);
	return new Date(fecha);
};

export const toJavaDate = (date) => moment(date).format('yyyy-MM-DD');

export const toJavaDateTime = (date) => moment(date).format('yyyy-MM-DD HH:mm');

export const calculaHoras = (fin, inicio )=>{
	const diff = fin.valueOf() - inicio.valueOf();
	const diffInHours = diff/1000/60/60; // Convert milliseconds to hours
	return diffInHours;
};
