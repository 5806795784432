import { memo, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import useObrasFetch from "../../hooks/useObrasFetch";
 import usePersonalProveedorFetch from "../../hooks/usePersonalProveedorFetch";
import useProveedoresFetch from "../../hooks/useProveedoresFetch";
import usePartesTrabajoFetch from "../../hooks/usePartesTrabajoFetch";
import AppLayout from "../../components/layout/AppLayout";
import AppBreadcrumb from "../../components/layout/AppBreadcrumb";
import { Button } from "primereact/button";
import { toJavaDateTime } from "../../services/commonHelpers";
import ListadoElement from "../../components/components/ListadoElement";

const PartesPendientesPage = ()=>{
    const { id } = useParams();
		const { obras } = useObrasFetch();
        const {partesACompletarObra} = usePartesTrabajoFetch({obra: id});

		const obra = useMemo(() => obras?.find((o) => o.id === parseInt(id, 10)), [obras, id]);
		 const { allPersonalProveedor } = usePersonalProveedorFetch();
		 const { proveedores } = useProveedoresFetch();

         const navigate= useNavigate();

		const breadcrumb = useMemo(
			() => [
				{
					label: 'Inicio',
					icon: 'pi pi-home',
					url: '/',
				},
				{
					label: 'Obras',
					url: '/obras',
				},
				{
					label: obra?.nombre,
					url: `/obra/${id}`,
				},
				{
					label: 'Partes incompletos',
				},
			],
			[obra?.nombre, id]
		);

        const datos = useMemo(() =>
					partesACompletarObra?.map((p) => ({
						...p,
						trabajadorNombre: allPersonalProveedor?.find(pp=>pp.id===p.persona)?.nombre,
						proveedorNombre: proveedores?.find((pp) => pp.id === p.proveedor)?.nombre,
					}))
				,[allPersonalProveedor, partesACompletarObra, proveedores]);

        const cumplimentarparte = useCallback(parte=>{
            navigate(`/cumplimentarParte/${id}/${parte?.id}`)
        },[id, navigate]);

		
		return (
			<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
				<div className='w-full p-2' style={{ maxHeight: '100vh' }}>
					<div className='surface-card shadow-2 border-round p-4'>
						<div className='flex justify-content-between align-items-center mb-5'>
							<span class='text-xl text-900 font-medium'>Partes incompletos</span>
							<div></div>
						</div>
						<ul className='list-none p-0 m-0'>
							{datos?.map((d) => (
								<ListadoElement
									icon='pi pi-user'
									titulo={d?.trabajadorNombre}
									bgcolor='bg-blue-50'
									subtitulo1={d.trabajadorNombre}
                                    badge={toJavaDateTime(d.horaEntrada)}
									right={
										<>
											<Button label='Completar'  onClick={()=>cumplimentarparte(d)}/>
										</>
									}
								/>
							))}
						</ul>
					</div>
				</div>
			</AppLayout>
		);

};
export default memo(PartesPendientesPage);