
import { sumaHoras, toJavaDateTime } from '../services/commonHelpers';
import { getPartesObraAbiertosHoyService, getPartesTrabajoPendientesCumplimentar, getPartesTrabajoProveedorHoy } from '../services/reactivaService';
import { useQuery } from '@tanstack/react-query';

const usePartesTrabajoFetch = ({obra, proveedor}) => {

	const { data: partesTrabajoAbiertos, isLoading: isLoadingPartesTrabajoAbiertos } = useQuery({
		queryKey: ['partesTrabajoAbiertos', obra],
		queryFn: async () => await getPartesObraAbiertosHoyService(obra),
	});


	const { data: partesTrabajoObraProveedorHoy } = useQuery({
		queryKey: ['partesTrabajoObraProveedorHoy', proveedor],
		queryFn: async () =>
			await getPartesTrabajoProveedorHoy(
				proveedor,
				toJavaDateTime(sumaHoras(new Date(), -16)),
				toJavaDateTime(sumaHoras(new Date(), 2))
			),
		enabled: !!proveedor
	});

	const { data: partesACompletarObra, isLoading: isLoadingPartesCompletarObra } = useQuery({
		queryKey: ['partesACompletarObra', obra],
		queryFn: async () => await getPartesTrabajoPendientesCumplimentar(obra),
		enabled: !!obra
	}); 


	return { partesTrabajoAbiertos, isLoadingPartesTrabajoAbiertos, partesTrabajoObraProveedorHoy, 
		partesACompletarObra, isLoadingPartesCompletarObra };
};

export default usePartesTrabajoFetch;
