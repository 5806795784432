import NavItem from '../../components/nav/NavItem';
import AppLayout from '../../components/layout/AppLayout';
import AppBreadcrumb from '../../components/layout/AppBreadcrumb';
import { memo, useMemo } from 'react';

const DesplazaMainPage = () => {
	const breadcrumb= useMemo(()=>([
		{
			label: 'Inicio',
			icon: 'pi pi-home',
			url: '/'
		},{
			label: 'Desplaza'
		}
	]),[]);
	
	return (
		<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
			<div className='w-full p-2' style={{ maxHeight: '100vh' }}>
				<div
					className='flex flex-column lg:flex-row gap-3 justify-content-between lg:align-items-center border-primary py-2'
					style={{ borderBottom: '7px solid' }}
				>
					<span className='text-900 font-medium text-2xl'> Seleccione opción </span>
				</div>
				<ul className='list-none p-1'>
					<li className='border-bottom-1 surface-border py-3md:align-items-center md:justify-content-between'>
						<NavItem title='Nuevo gasto' icon='pi pi-ticket' url='/nuevoGasto' />
					</li>
					<li className='border-bottom-1 surface-border py-3 md:align-items-center md:justify-content-between'>
						<NavItem title='Mis gastos' icon='pi pi-list' url='/listadoGastos' />
					</li>
				</ul>
			</div>
		</AppLayout>
	);
};

export default memo(DesplazaMainPage);
