import { useQuery } from '@tanstack/react-query';
import { getProveedoresObraService, getProveedoresService } from '../services/reactivaService';


const useProveedoresFetch = ({ obra }={}) => {
	const { data: proveedores, isLoading: isLoadingProveedores } = useQuery({
		queryKey: ['proveedores'],
		queryFn: async () => await getProveedoresService(),
	});

	const { data: proveedoresObra, isLoading: isLoadingProveedoresObra } = useQuery({
		queryKey: ['proveedoresObra', obra],
		queryFn: async () => await getProveedoresObraService(obra),
		enabled: !!obra,
	});

	// const proveedoresOut = useMemo(()=>(
	// 	orderBy(proveedores, 'nombre')
	// ),[proveedores]);

	return { proveedores, isLoadingProveedores, proveedoresObra, isLoadingProveedoresObra };
};

export default useProveedoresFetch;
