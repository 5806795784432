import { Button } from 'primereact/button';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router';

const NavItem = ({ icon, title, description, url }) => {
    const navigate = useNavigate();

    const navigateUrl = useCallback(()=>{
        navigate(url);
    },[url,navigate]);

	return <>
		<div className='p-3 border-round shadow-2 flex align-items-center surface-card cursor-pointer' 
			onClick={navigateUrl}
		>
			<div
				className='bg-teal-100 inline-flex align-items-center justify-content-center mr-3'
				style={{ width: '48px', height: '48px', borderRadius: '10px' }}
			>
				<i className={`${icon} text-teal-600 text-3xl`}></i>
			</div>
			<div>
				<span className='text-900 text-xl font-medium mb-2'>{title}</span>
				<p className='mt-1 mb-0 text-600 font-medium text-sm'>{description}</p>
			</div>
			<div className='ml-auto'>
				<Button label='>' onClick={navigateUrl} />
			</div>
		</div>
	</>;
};

export default memo(NavItem);
