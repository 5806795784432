import NavItem from '../../components/nav/NavItem';
import AppLayout from '../../components/layout/AppLayout';
import AppBreadcrumb from '../../components/layout/AppBreadcrumb';
import { memo, useMemo } from 'react';
import useObrasFetch from '../../hooks/useObrasFetch';

const ObrasPage = () => {
	const breadcrumb = useMemo(
		() => [
			{
				label: 'Inicio',
				icon: 'pi pi-home',
				url: '/',
			},
			{
				label: 'Obras',
			},
		],
		[]
	);

    const { obras, obrasUsuario } = useObrasFetch();

      const obrasF = useMemo(
				() => obras
				?.filter(o=>!o.fechaFin)
				?.filter((o) => obrasUsuario?.find((op) => op.obra === o.id)),
				[obras, obrasUsuario]
			);


	return (
		<AppLayout breadcrumb={<AppBreadcrumb items={breadcrumb} />}>
			<div className='w-full p-2' style={{ maxHeight: '100vh' }}>
				<div
					className='flex flex-column lg:flex-row gap-3 justify-content-between lg:align-items-center border-primary py-2'
					style={{ borderBottom: '7px solid' }}
				>
					<span className='text-900 font-medium text-2xl'> Seleccione opción </span>
				</div>
				<ul className='list-none p-1'>
					{obrasF?.map((o) => (
						<li
							key={o.id}
							className='border-bottom-1 surface-border py-1 md:align-items-center md:justify-content-between'
						>
							<NavItem title={o.nombre} icon='pi pi-building' url={`/obra/${o.id}`} />
						</li>
					))}
				</ul>
			</div>
		</AppLayout>
	);
};

export default memo(ObrasPage);
