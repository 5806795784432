import { memo } from "react";
import PropTypes from 'prop-types';
import useObrasFetch from "../../hooks/useObrasFetch";
import {  toJavaDateTime } from "../../services/commonHelpers";

const EstadoPartesObra = ({datos, obra}) =>{
    const {obras} = useObrasFetch();
    return (
			<ul>
				{datos?.partes?.map((d) => (
					<li>
						<span className='font-medium text-green-400'>{obras?.find((o) => o.id === d.obra)?.nombre}</span>
						<span className='ml-2'>{d.horaEntrada ? toJavaDateTime(d.horaEntrada) : ''}</span>
						<span className='ml-2'>
							--
							{d.horaSalida ? toJavaDateTime(d.horaSalida) : ''}
						</span>
					</li>
				))}
				{datos?.partes?.length === 0 && <div> No hay fichajes </div>}
			</ul>
		);
};

EstadoPartesObra.propTypes={
    datos: PropTypes.object
}

export default memo(EstadoPartesObra);