import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useCallback, useContext, useMemo, useState } from "react";
import useObrasFetch from "../../hooks/useObrasFetch";
import { InputText } from "primereact/inputtext";
import useProveedoresFetch from "../../hooks/useProveedoresFetch";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { saveParteTrabajoService } from "../../services/reactivaService";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import AppContext from "../../contexts/AppContext";
import usePersonalProveedorFetch from "../../hooks/usePersonalProveedorFetch";
import orderBy from 'lodash/orderBy';
import { calculaHoras } from "../../services/commonHelpers";

const FormularioParteObra = ({parte, onClose, desplazamiento})=>{
    const { user } = useContext(AppContext);
     const queryClient = useQueryClient();
    const {obras} = useObrasFetch();
    const {proveedores} = useProveedoresFetch();
	
	const { allPersonalProveedor } = usePersonalProveedorFetch();

	const personal = useMemo(()=>(
		orderBy(allPersonalProveedor?.filter(p=>p.proveedor===56 && p.habilitado),'nombre')
	),[allPersonalProveedor]);

    const [datos, setDatos] = useState({...parte
        , horaEntrada: parte?.horaEntrada ? new Date(parte?.horaEntrada):null,
    horaSalida: parte?.horaSalida? new Date(parte?.horaSalida): new Date()});
     const changeAttr = useCallback(
				(attr, val) => {
					const newD = { ...datos };
					newD[attr] = val;
					setDatos(newD);
				},
				[datos]
			);

    const saveParteTrabajo = useCallback(async ()=>{
        const parteAct = {...datos,
            usuarioModificacion: user?.id,
            fechaModificacion: new Date(),
			horasReal: calculaHoras(datos?.horaSalida, datos?.horaEntrada),
			desplazamiento
        };
        if (datos?.horaEntrada && datos?.horaSalida && datos?.horas && datos?.trabajoRealizado){
            const res = await saveParteTrabajoService(parteAct);
            if (res.error){
                toast.error(res.mensaje);
            }else{
                queryClient.invalidateQueries({ queryKey: ['partesTrabajoAbiertos'] });
                queryClient.invalidateQueries({ queryKey: ['partesTrabajoObraProveedorHoy'] });
				queryClient.invalidateQueries({ queryKey: ['partesACompletarObra'] });
                toast('Fichaje guardado correctamente');
                onClose();
            }
        }else{
            toast.error('Complete datos obligatorios');
        }
    },[datos, user?.id, desplazamiento, queryClient, onClose]);
    
    return (
			<div className='grid formgrid m-2  surface-card border-round-sm'>
				{desplazamiento && (
					<div className='field mb-0 col-12'>
						<label for='nombre' className={`font-medium text-900 text-xs ${!datos?.horaEntrada ? 'text-red-400' : ''}`}>
							Persona
						</label>
						<Dropdown
							value={datos?.persona}
							onChange={(e) => changeAttr('persona', e.value)}
							options={personal?.map((m) => ({ label: m.nombre, value: m.id }))}
							className=' w-full mb-0  p-0 text-sm'
							panelClassName='text-sm'
							filter
						/>
					</div>
				)}
				<div className='field mb-0 col-12'>
					<label for='nombre' className={`font-medium text-900 text-xs ${!datos?.horaEntrada ? 'text-red-400' : ''}`}>
						Hora Entrada
					</label>
					<Calendar
						showTime
						locale='es'
						value={datos?.horaEntrada}
						disabled={!desplazamiento}
						onChange={(e) => changeAttr('horaEntrada', e.value)}
						className=' w-full mb-0  p-0 text-sm'
						panelClassName='text-sm'
					/>
				</div>
				<div className='field mb-0 col-12'>
					<label for='nombre' className={`font-medium text-900 text-xs ${!datos?.horaEntrada ? 'text-red-400' : ''}`}>
						Hora Salida
					</label>
					<Calendar
						locale='es'
						showTime
						value={datos?.horaSalida}
						onChange={(e) => changeAttr('horaSalida', e.value)}
						className=' w-full mb-0  p-0 text-sm'
						panelClassName='text-sm'
					/>
				</div>
				<div className='field mb-0 col-12'>
					<label for='nombre' className={`font-medium text-900 text-xs ${!datos?.importe ? 'text-red-400' : ''}`}>
						Horas
					</label>
					<InputNumber
						className='w-full mb-0  p-0 text-sm'
						value={datos?.importe}
						onChange={(e) => changeAttr('horas', e.value)}
					/>
				</div>
				<div className='field mb-0 col-12'>
					<label for='nombre' className={`font-medium text-900 text-xs ${!datos?.obra ? 'text-red-400' : ''}`}>
						Obra
					</label>
					<Dropdown
						disabled
						value={datos?.obra}
						onChange={(e) => changeAttr('obra', e.value)}
						options={obras?.map((m) => ({ label: m.nombre, value: m.id }))}
						className=' w-full mb-0  p-0 text-sm'
						panelClassName='text-sm'
						filter
					/>
				</div>
				<div className='field mb-0 col-12'>
					<label for='nombre' className={`font-medium text-900 text-xs ${!datos?.proveedor ? 'text-red-400' : ''}`}>
						Proveedor
					</label>
					<Dropdown
						disabled
						value={datos?.proveedor}
						onChange={(e) => changeAttr('proveedor', e.value)}
						options={proveedores?.map((m) => ({ label: m.nombre, value: m.id }))}
						className=' w-full mb-0  p-0 text-sm'
						panelClassName='text-sm'
						filter
					/>
				</div>

				<div className='field mb-0 col-12'>
					<label
						for='nombre'
						className={`font-medium text-900 text-xs ${!datos?.trabajoRealizado ? 'text-red-400' : ''}`}
					>
						Trabajo realizado
					</label>
					<InputText
						className='p-inputtext w-full mb-0  p-1 text-sm'
						value={datos?.trabajoRealizado}
						onChange={(e) => changeAttr('trabajoRealizado', e.target.value)}
					/>
				</div>

				<div className='field mb-0 col-12'>
					<label for='nombre' className='`font-medium text-900 text-xs'>
						Observaciones
					</label>
					<InputText
						className='p-inputtext w-full mb-0  p-1 text-sm'
						value={datos?.observaciones}
						onChange={(e) => changeAttr('observaciones', e.target.value)}
					/>
				</div>

				<div className='col-12 mt-2 align-content-end'>
					<Button label='Guardar' className='text-sm align-items-end mr-2' onClick={saveParteTrabajo} />
				</div>
			</div>
		);
    
};

export default FormularioParteObra;